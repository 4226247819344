.thirdpythonlibrary {
  background-color: #0f00dd;
  color: white;
}

.thirdpythonlibrary .cards-wrapper .card {
  background-color: rgba(21, 255, 0, 0.513);
}

.thirdpythonlibrary .image-wrapper {
    text-align: right;
}
 