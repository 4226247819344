p {
    padding-right: 40px;
    padding-left: 40px;
}

.second {
    background-color: #ffff59;
    font-size: 0.9rem;
}

 

h3 {
    font-size: 3rem;
}



@media only screen and (max-height: 700px) {
    .h3 {
        font-size: 0.5rem;
    }
    .second {
        
        font-size: 0.2rem;
    }
     
  }