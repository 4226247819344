.FirstBox {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  color: white;
  width: 100%;
  border: 0px solid green;
  z-index: 100000;
}
.rightPlace {
  font-size: 1.2rem;
  font: bold;
  font-weight: 700;
  position: relative;
  float: left;
  left: 1%;
}

@media only screen and (max-width: 900px) {
  .rightPlace {
    font-size: 0.4rem;
  }
}