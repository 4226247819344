.CloudComputing {
  background-color: #ec5f01;
   color: white;
}

.CloudComputing .cards-wrapper .card {
  background-color: #525050;
}

.CloudComputing .image-wrapper {
    text-align: right;
}
 