.third {
    background-color: #c0c0c0;
}


.third .cards-wrapper .card {
    background-color: #ff0000;
  }
.cards-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}