.thirdAIMLANDNN {
  background-color: #ffff59;
   
}

.thirdAIMLANDNN .cards-wrapper .card {
  background-color: rgb(0, 0, 0);
}

.thirdAIMLANDNN .image-wrapper {
    text-align: right;
}
 