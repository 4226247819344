.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 200px;
  width: 120px;
  background-color: white;
  margin: 7px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  background-color: rgba(240, 22, 22, 0.698);
  color: white;
  
}

.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.image-wrapper {
  width: 60%;
  height: 70%;
  text-align: center;
  padding: 3px;
  filter:invert(100%);
}

img {
  max-width: 100%;
  max-height: 100%;
}

.skill-title-wrapper {
  padding: 5px;
  height: 100px;
  width: 110px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
}

h4 {
  margin: 0;
  font-size: 20px;
}
 
@media only screen and (max-height: 700px) {
  .skill-title-wrapper {
    padding: 5px;
    height: 45px;
    width: 30px;
  }

  .card {
    display: flex;

    height: 100px;
    width: 90px;
    margin: 5px;
    
  }

  h4 {
    font-size: 15px;
  }
}
