.fullpage {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media only screen and (max-height: 700px) {
    .fullpage {
      font-size: smaller;
    }
   
  }
  